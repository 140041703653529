/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/display-name */
import React, { useEffect, ComponentType } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { syncWithLocalStorage } from 'state/auth/auth.actions'

export function WithAuth<P>(WrappedComponent: ComponentType<P>): ComponentType<P> {
  return (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
      dispatch(syncWithLocalStorage((url: string) => history.push(url)))
    })
    return <WrappedComponent {...props} />
  }
}
