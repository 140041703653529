import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router'
import {
  // fetchBusinessCategoriesAction,
  // fetchBusinessIdsAction,
  // fetchBusinessTypesAction,
  // fetchEntityList,
  fetchHelpSections
  // fetchLocalData,
  // fetchPhonebookContacts
} from 'state/dashboard/dashboard.actions'
import { WithAuth } from 'shared/components/WithAuth'
// import { getAccountTags, getTags } from 'state/tags/tags.actions'
import { IAppState } from 'infra/AppState'
// import { ENTITY_TYPE } from 'shared/constants'
import { newPaths } from 'routes'
// import { NotificationList } from '../Notification/NotificationList'
// import DashboardStyles from './Dashboard.styles'
// import { CommonNavHeader } from './Helper'
// import RenderRoutes from './RenderRoutes'

const Dashboard: React.FC<any> = () => {
  // const classes = DashboardStyles()
  const dispatch = useDispatch()
  const dashboardState = useSelector((state: IAppState) => state.Dashboard)
  const location = useLocation()

  // useEffect(() => {
  //   dispatch(fetchLocalData())
  //   if (dashboardState?.businessAccounts?.businessIds?.length === 0) {
  //     dispatch(fetchBusinessIdsAction({ isSigningIn: false }))
  //   }
  //   dispatch(fetchBusinessCategoriesAction())
  //   dispatch(fetchBusinessTypesAction())
  //   dispatch(fetchPhonebookContacts())
  //   dispatch(getTags())
  //   dispatch(getAccountTags())
  //   dispatch(fetchEntityList(ENTITY_TYPE.CUSTOMER))
  //   dispatch(fetchEntityList(ENTITY_TYPE.SUPPLIER))
  // }, [])

  useEffect(() => {
    if (dashboardState.merchantProfile.data?.id) {
      dispatch(fetchHelpSections(dashboardState.merchantProfile.data.id))
    }
  }, [dashboardState.merchantProfile.data?.id])

  if (location.pathname.includes('app-blocked')) {
    return null
  }

  return <Redirect to={newPaths.base} />
  //  <a href="#start-of-content" className={classes.skipToContent}>
  //   Skip to content
  // </a>
  // <div className={classes.startOfContent} id="start-of-content">
  //   <CommonNavHeader />
  //   <NotificationList />
  //   <RenderRoutes />
  // </div>
}

export default WithAuth(Dashboard)
